
import { defineComponent } from 'vue';
import { JobSectors } from '@/content/JobSectorContent';

export default defineComponent({
  name: 'SectorLink',
  props: {
    sectorKey: String,
  },
  setup(props) {
    const jobSectors: {[index: string]:any} = JobSectors;
    const linkText = jobSectors[String(props.sectorKey)].intro.title;
    return {
      linkText,
    };
  },
});
