import PlainContent from '@/types/PlainContent';

const ResultsContent: PlainContent = {
  title: 'Next Steps',
  body: `<p>
          We'd love to welcome you into our HPC family.
        </p> 
        <p>
          Our Jobs Service is dedicated to matching people to available opportunities within Hinkley Point C and the local supply chain.
        </p>
        <p>
          Use the link below to search for current opportunities or if there are non-available right now let us know which job family you’d like to be a part of and register for alerts to be the first to hear roles become available.
        </p>`,
  buttonText: 'Visit HPC Jobs Service',
  buttonUrl: 'https://www.edfenergy.com/energy/nuclear-new-build-projects/hinkley-point-c/jobs-and-training',
  externalUrl: true,
};

export default ResultsContent;
