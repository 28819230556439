import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Results2 = _resolveComponent("Results2")!
  const _component_ContentBanner = _resolveComponent("ContentBanner")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_Results2),
    _createVNode(_component_ContentBanner, {
      content: _ctx.learnMoreContent,
      inverted: true
    }, null, 8, ["content"]),
    _createVNode(_component_Footer)
  ], 64))
}