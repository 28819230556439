
import { defineComponent, computed } from 'vue';
import store from '@/store';
import Criteria from '@/data/criteria.json';
import { Outcome, OutcomeSuitability } from '@/lib/totaliser';
import ResultsContent from '@/content/ResultsContent';
import PlainContent from '@/types/PlainContent';
import { JobSectors } from '@/content/JobSectorContent';
import SectorLink from '@/components/SectorLink.vue';
import QuestionnaireActions from '@/components/QuestionnaireActions.vue';

export default defineComponent({
  name: 'Results',
  components: {
    SectorLink,
    QuestionnaireActions,
  },
  setup() {
    const resultsContent: PlainContent = ResultsContent;
    const { results } = store.state;
    const jobFamily = computed(() => {
      const topResult: Outcome = results[0];
      let topJobFamily = '';
      Object.keys(Criteria.criteria).forEach((criteriaKey) => {
        if (Criteria.criteria[Number(criteriaKey)].title === topResult.id) {
          topJobFamily = Criteria.criteria[Number(criteriaKey)].family;
        }
      });
      return topJobFamily;
    });
    const filteredJobSectorsFamilies = computed(() => {
      const jobSectors: { [index: string]: any } = JobSectors;
      let sectorSlug = '';
      let bodyText = '';
      let imgURL = '';
      if (jobFamily.value) {
        Object.keys(jobSectors).forEach((sector) => {
          const jobSectorFamilies = jobSectors[sector].families;
          Object.keys(jobSectorFamilies).forEach((family) => {
            const familyTitle = jobSectorFamilies[family].title;
            if (familyTitle === jobFamily.value) {
              sectorSlug = sector;
              bodyText = jobSectorFamilies[family].body;
              imgURL = jobSectorFamilies[family].imgMobile;
            }
          });
        });
        return { sectorSlug, body: bodyText, img: imgURL };
      }
      return null;
    });
    const suggestedJobs = computed(() => {
      const jobs: any[] = [];
      let jobsCounter = 0;
      Object.keys(results).forEach((resultKey) => {
        Object.keys(Criteria.criteria).forEach((criteriaKey) => {
          const result: OutcomeSuitability = results[Number(resultKey)];
          if (
            result.id === Criteria.criteria[Number(criteriaKey)].title
            && Criteria.criteria[Number(criteriaKey)].family === jobFamily.value
            && jobsCounter < 6
          ) {
            jobs.push(Criteria.criteria[Number(criteriaKey)].title);
            jobsCounter += 1;
          }
        });
      });
      return jobs;
    });
    return {
      results,
      resultsContent,
      jobFamily,
      suggestedJobs,
      filteredJobSectorsFamilies,
    };
  },
});
