import PlainContent from '@/types/PlainContent';

const LearnMoreContent: PlainContent = {
  title: 'Learn More...',
  body: `<p>
          Hinkley Point C is both revolutionary and ambitious; we’re dedicated to supporting the community by providing over 25,000 accessible employment opportunities, investing in the training of local people, and making a vital contribution to a carbon-reduced future.
        </p> 
        <p>
          If you’d like to learn more about the incredible progress we’re making, you can use the links below:
        </p>
        <ul>
            <li>
              <a
                href="https://www.edfenergy.com/energy/nuclear-new-build-projects/hinkley-point-c/for-teachers-students-and-educators/young-hpc"
                target="_blank"
                rel="noopener"
              >Young HPC</a>
            </li>
            <li>
              <a
                href="https://www.edfenergy.com/energy/nuclear-new-build-projects/hinkley-point-c/about"
                target="_blank"
                rel="noopener"
              >About Hinkley Point C</a>
            </li>
            <li>
              <a
                href="https://www.edfenergy.com/energy/nuclear-new-build-projects/hinkley-point-c/about/news-and-views"
                target="_blank"
                rel="noopener"
              >Latest News on Hinkley Point C</a>
            </li>
        </ul>`,
};

export default LearnMoreContent;
