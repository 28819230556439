
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'QuestionnaireActions',
  setup() {
    const router = useRouter();
    const store = useStore();
    const questions = store.getters.getQuestions;
    const resetQuestionnaire = () => {
      for (let i = 1; i <= questions.length; i += 1) {
        store.commit('UPDATE_RESPONSE', { id: `q${i}`, responseIndex: null });
      }
      router.push({ name: 'Questionnaire' });
    };
    return {
      resetQuestionnaire,
    };
  },
});
