
import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Results2 from '@/components/Results2.vue';
import ContentBanner from '@/components/ContentBanner.vue';
import LearnMoreContent from '@/content/FeedbackContent';
import PlainContent from '@/types/PlainContent';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'Feedback',
  components: {
    Header,
    Results2,
    Footer,
    ContentBanner,
  },
  setup() {
    const learnMoreContent: PlainContent = LearnMoreContent;

    return {
      learnMoreContent,
    };
  },
});
